
import Avatar from "@/components/ui/Avatar.vue";
import Btn from "@/components/ui/Btn.vue";
import CopyToClipboard from "@/components/ui/CopyToClipboard.vue";
import ProcessedTextFragment from "@/components/ui/ProcessedTextFragment.vue";
import Timestamp from "@/components/ui/Timestamp.vue";
import { getTranslatedString as _ } from "@/i18n";
import { courseIdMixin, nodeMixin } from "@/mixins";
import { AnnouncementNode, AnnouncementNodeState } from "@/models";
import { useMainStore } from "@/stores/mainStore";
import { sameDay } from "@/utils";
import { defineComponent, PropType } from "@vue/runtime-core";
import { mapStores } from "pinia";
import CourseTreeNodeCommentSection from "../CourseTreeNodeCommentSection.vue";
import { nodeEmits, nodeProps } from "../shared";
import CourseTreeNode from "./CourseTreeNode.vue";
export default defineComponent({
	name: "AnnouncementNode",
	props: {
		node: {
			type: Object as PropType<AnnouncementNode>,
			required: true,
		},
		...nodeProps,
	},
	mixins: [courseIdMixin, nodeMixin],
	created() {
		this.$emit("loadChildren", { node: this.node, fromFirstPage: true });
		this.$emit("loadComments", this.node);
	},
	data() {
		return {
			AnnouncementNodeState,
		};
	},
	emits: {
		...nodeEmits,
	},
	methods: {
		onShowMore() {
			// TODO implement
		},
		onEdit() {
			this.$emit("editNode", this.node);
		},
		onDelete() {
			this.$emit("deleteNode", this.node);
		},
	},
	computed: {
		...mapStores(useMainStore),
		children() {
			return this.mainStore.paginatedChildrenByNodeId[this.node.id]?.data ?? [];
		},
		bodyPreview() {
			return this.node.body;
		},
		isTruncated() {
			// TODO implement
			return false;
		},
		updatedOnDifferentDay() {
			// TODO use moment
			return !sameDay(new Date(this.node.created), new Date(this.node.modified));
		},
	},
	// eslint-disable-next-line vue/no-unused-components
	components: {
		ProcessedTextFragment,
		Timestamp,
		Btn,
		CopyToClipboard,
		CourseTreeNode,
		CourseTreeNodeCommentSection,
		Avatar,
	},
});
